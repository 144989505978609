<template>
  <div class="email-lists">
    <sub-header :handleClick="handleShowModal" :backButton="true" :showBtn="true" buttonIcon="envelope-open"  buttonText="Create Email Template" />
    <div class="px-6 m-auto lg:w-12/12 xl:w-10/12 justify-between md:w-12/12 mx-h-full overflow-hidden" >
      <dv-table :headers="getTableData.columns" :rows="getTableData.rows" redirectUrl="email-template-admin" :isClickable="false">
          <template v-slot:items="{ row }" class="max-h-full overflow-y-auto">
              <td class="flex-wrap py-4 px-3 text-sm border-0 border-b border-solid border-gray-100" >
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-4">
                      <div class=" p-2 bg-primary flex justify-center items-center rounded-lg ">
                        <font-awesome-icon icon="envelope" class="text-2xl text-white-text "></font-awesome-icon>
                      </div>
                    <div >
                      <div class="font-medium text-gray-700 text-md">{{row.EmailName}}</div>
                      <div class="text-gray-500 text-xs"> {{row.EmailDesc}} </div>
                    </div>
                  </div>
                  <div>
                    <font-awesome-icon icon="angle-right" class=" text-brand w-32 text-2xl self-center cursor-pointer hover:opacity-70" />
                  </div>

                </div>
                </td>
               
          </template>
          <template v-slot:no-data>
              <span class="w-full flex items-center justify-center p-3">No data Found</span>
          </template>
      </dv-table>
    </div>
    <dv-modal
    v-show="isModalVisible"
    @close="closeModal"
    >
        <template v-slot:header>
            <span class="text-gray-500">Create New Email template</span>
        </template>

        <template v-slot:body>
            <div class="email_template_body w-full py-6 h-full text-gray-500 flex flex-col justify-center gap-6">
            
              <!-- <h3 class="m-0">Folder Name</h3> -->
              <input v-model="EmailData.EmailName" type="text" placeholder="Email Template Name"
                class="border-0 text-gray-600 font-normal border-b-2 border-solid border-gray-500 outline-none h-8 text-lg focus:border-dvbrandhoveron w-full">

                <input v-model="EmailData.EmailDesc" type="text" placeholder="Email template  Description"
                class="border-0 text-gray-600 font-normal border-b-2 border-solid border-gray-500 outline-none h-10 text-lg focus:border-dvbrandhoveron w-full">
                
            
            </div>
        </template>

        <template v-slot:footer>
            <div class="flex w-full h-full justify-end">
                <neo-button
                    :loader="isLoading"
                    :disabled="!EmailData.EmailName.length || isLoading"
                    @click="handleCreateEmailTemplate"
                    text="Create Folder"
                    icon="plus"
                    class="text-white-text flex gap-1"
                >
                </neo-button>
            </div>
        </template>
    </dv-modal>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import dvTable from  "@/components/devliumComponent/field-table/dv-table"
import DvModal from "@/components/devliumComponent/dv-modal/dv-modal"
export default {
  name: "email-lists",
  components: {
    SubHeader,
    "neo-button": Button,
    dvTable,
    DvModal,
  },
  props: {},
  data() {
        return {
            isModalVisible: false,
            EmailTemplates: [],
            EmailData: {
                EmailName: '',
                EmailDesc: ''
            },
            tableData: {
                columns: [{"label":"Email Templates","field":"EmailData"}],
                rows:[]
            },
            isLoading: false,
        };
    },
    computed: {
        getTableData(){
          return {}
            // return this.tableData = {...this.tableData, rows:this.EmailTemplates}
        },
    },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  methods: {
        fetchData() {
            // let query = this.$route
            
        },
        handleShowModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        handleCreateEmailTemplate(){
            this.isLoading = true 
            if (this.EmailData.EmailName === "") return
            // Settimeout only for demo Loading Purpose ... remove on API Integration
            setTimeout(() => {
                let email_date = {
                    EmailName: this.EmailData.EmailName,
                    emailDesc: this.EmailData.EmailDesc,
                    id: Math.round(Math.random() * 1000000000), // for dummy content only
                }
                this.EmailTemplates.push(email_date)
                this.EmailData = {
                    EmailName: '',
                    EmailDesc: ''
                },
                this.isLoading = false
                this.closeModal()
                
            }, 1000)
        },
  },
};
</script>
<style scoped lang="scss">
    .email-lists{
    input::placeholder{
        font-size: 16px;
        color: rgb(168, 168, 168);
    }
}
</style>