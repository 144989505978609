<template>
  <transition name="modal-fade">
    <div class="dv-modal-backdrop fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center shadow-lg">
      <div class="dv-modal bg-white flex flex-col"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
          <slot name="header">
            Header Title
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            <font-awesome-icon icon="times"> </font-awesome-icon>
          </button>
        </header>

        <section
          class="modal-body scroll-bar"
          id="modalDescription"
        >
          <slot name="body">
             default body!
          </slot>
        </section>

        <footer class="modal-footer">
            <slot name="footer">
                Deafult Footer
            </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    name: 'dv-modal',
    components: {},
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
<style>
  .dv-modal-backdrop {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .dv-modal {
    /* box-shadow: 2px 2px 20px 1px; */
    max-width: 40%;
    min-width: 30%;
    min-height: 20%;
    max-height: 60%;
    border-radius: 8px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
  }

  .modal-body {
    position: relative;
    padding: 15px;
    flex:1;
    overflow-y: auto;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: var(--dv-brand-color);
    background: transparent;
  }

  .btn-green {
    color: white;
    background: var(--dv-brand-color);
    border: 1px solid var(--dv-brand-hover-on);
    border-radius: 2px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .1s ease;
  }
</style>